import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "react-slideshow-image/dist/styles.css";

// Modules
import * as serviceWorker from "./serviceWorker";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
//Custom Components
import SuspenseFallback from "./Components/root/SuspenseFallback";
import ErrorBoundary from "./Components/root/ErrorBoundary";
import { UserBaseContext } from "./Utilities/UserContext";

// Theming
import { MuiThemeProvider } from "@material-ui/core/styles";
import { HD_Main } from "./Themes";

// Views
// const Finance = React.lazy(() => import("./Views/Finance"));
const CreditCard = React.lazy(() => import("./Views/CreditCard"));
const PageNotFound = React.lazy(() => import("./Components/root/PageNotFound"));

// Init a root function
const BaseIndexApp = () => {
  const CreditUser = localStorage.getItem("CreditCard");
  const [UserBase, setUserBase] = React.useState({ CreditCard: CreditUser });
  const hist = createBrowserHistory();
  return (
    <UserBaseContext.Provider value={{ UserBase, setUserBase }}>
      <React.Suspense fallback={<SuspenseFallback />}>
        <Router history={hist}>
          <Switch>
            <Route path="/CreditCard" component={CreditCard} />
            <Redirect from="/" to="/CreditCard/Home" />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Router>
      </React.Suspense>
    </UserBaseContext.Provider>
  );
};

// Render the app
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MuiThemeProvider theme={HD_Main}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BaseIndexApp />{" "}
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
