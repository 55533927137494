import { createMuiTheme } from "@material-ui/core/styles";

export const HD_Main = createMuiTheme({
  palette: {
    primary: {
      main: "#f50",
      light: "#f50",
      dark: "#111",
    },
    error: {
      main: "#e00000",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});
